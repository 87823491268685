import axios from 'axios';

import { SessionUtils } from './SessionUtils';

/**
 * Holds various function for communicating with CRCCARE tools UserService.
 *
 * @namespace
 */
export const UserService = {
  /**
   * Function that verifies user token with User Service and returns a boolean.
   *
   * @return   {Promise}         Token verified status (boolean).
   */
  verify: async (token = SessionUtils.getToken()) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/verify`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
      .then((reply) => {
        return reply.status === 200;
      })
      .catch(() => {
        return false;
      });
  },

  /**
   * Function that verifies user token with User Service and returns an object containing that user's data.
   *
   * @return   {Promise}         Verified user data (Object).
   */
  verifyGet: async (token = SessionUtils.getToken()) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/verify`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
      .then((reply) => {
        return reply.status === 200 ? reply.data : null;
      })
      .catch(() => {
        return null;
      });
  },

  /**
   * Function that gets a user data. Returns current user if nothing is input
   *
   * @param    {String} id         User ID.
   * @return   {Promise}           User data (Object).
   */
  getUser: async (id = SessionUtils.getUserData().id) => {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/users/${id}`,
      headers: {
        authorization: `Bearer ${SessionUtils.getToken()}`,
      },
    })
      .then((reply) => {
        return reply.data;
      })
      .catch(() => {
        SessionUtils.clearSession();
        return {};
      });
  },

  /**
   * Function that gets all user data. For admin only.
   *
   * @return   {Promise}        All user's data (Array)
   */
  getAllUsers: async () => {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/users?show=1000`,
      headers: {
        authorization: `Bearer ${SessionUtils.getToken()}`,
      },
    })
      .then((reply) => {
        return reply.data;
      })
      .catch(() => {
        return [];
      });
  },

  /**
   * Function that delete user data. Returns updated user data
   *
   * @param    {String} id         User ID.
   * @return   {Promise}           User data (Object).
   */
  deleteUser: async (id) => {
    return axios({
      method: 'PATCH',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/users/${id}`,
      headers: {
        Authorization: `Bearer ${SessionUtils.getToken()}`,
      },
      data: { status: 'inactive' },
    });
  },

  /**
   * Function that create new user. Returns new user data
   *
   * @param    {Object} data       User data.
   * @return   {Promise}           User data (Object).
   */
  addUser: async (data) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/register`,
      headers: {
        'content-type': 'application/json',
      },
      data: data,
    });
  },
};
