import React from 'react';

/**
 * Component for Page Title UI.
 *
 * @component
 */
export const PageTitle = ({ title, description, icon, right, className }) => (
  <div className="flex flex-row w-full bg-gray-50 px-10 py-12 mb-6">
    <div className="flex flex-row w-full justify-start content-center">
      <div className="hidden content-center justify-center h-14 w-14 mr-8 bg-gray-50 rounded-sm shadow-lg lg:flex">
        {!icon ? (
          <span
            className={`self-center text-3xl text-green-700 ${className}`}
          ></span>
        ) : (
          <span className={`self-center text-3xl text-green-700 w-8`}>
            <img src={icon} alt="icon" />
          </span>
        )}
      </div>
      <div className="flex flex-col content-center justify-center">
        <h2 className="text-xl">{title}</h2>
        {description && (
          <h3 className="text-sm mt-1 text-gray-500">{description}</h3>
        )}
      </div>
    </div>
    {right && <div className="self-center">{right}</div>}
  </div>
);
