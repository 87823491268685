import React from 'react';
import { PageTitle } from '../../components/PageTitle';

const Dashboard = () => {
  const switcherCards = [
    {
      key: 'M',
      title: 'mineCARE',
      route: 'https://minecare.crccaretools.com.au/',
    },
    {
      key: 'I',
      title: 'irCARE',
      route: 'https://ircare.crccaretools.com.au/',
    },
    {
      key: 'R',
      title: 'riskCARE',
      route: 'https://riskcare.crccaretools.com.au/',
    },
    {
      key: 'R',
      title: 'rankCARE II',
      route: 'https://rankcare.crccaretools.com.au/',
    },
  ];

  return (
    <div>
      <PageTitle
        title="Dashboard"
        description="Welcome! What do you want to do?"
        className="pe-7s-rocket"
      />

      <div className="flex flex-col items-center px-6 pt-6 pb-6 space-y-6 lg:space-y-0 lg:flex-row lg:justify-evenly ">
        {switcherCards.map((item) => (
          <a
            key={item.key}
            href={item.route}
            className="group inline-flex flex-col w-44 h-44 items-center justify-center bg-white p-2 space-y-2 rounded shadow-md transition duration-200 hover:bg-xbluetint focus:bg-xbluetint focus:text-green-500"
          >
            <div className="self-center dashboard-avatar border-1 border-gray-400 bg-gray-400 group-hover:bg-white group-focus:bg-white transition duration-200">
              <span className="text-2xl font-semibold text-white group-hover:text-black group-focus:text-green-400 transition duration-200">
                {item.key}
              </span>
            </div>
            <div className="whitespace-nowrap menu-text self-center font-semibold text-lg">
              {item.title}
              <sup>TM</sup>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
