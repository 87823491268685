import axios from 'axios';
import { overwriteResource, setResource } from '../store/actions/resources';
import { SessionUtils } from '../utils';

const baseURL = process.env.REACT_APP_USER_SERVICE_URL;
const instance = axios.create({
  baseURL,
});

export const applyInterceptors = (dispatch, getState) => {
  // intercept all requests and add the authorization token before sending it to the server
  // this helps us to not manually adding the token every time
  instance.interceptors.request.use(
    (config) => {
      // const token = window.localStorage.getItem('token'); // or anywhere that you store the token
      const token = SessionUtils.getToken();
      config.headers.Authorization = token ? `Bearer ${token}` : '';

      // these configuration will be passed to response and we can automatically map data to redux store
      config.resourceName = config.headers.resourceName;
      config.overwrite = config.headers.overwrite;

      return config;
    },
    (err) => Promise.reject(err)
  );

  // set the resource in the redux store on response
  instance.interceptors.response.use((res) => {
    const { config, data } = res;

    if (config.overwrite) {
      dispatch(overwriteResource(config.resourceName, data));
    } else {
      dispatch(setResource(config.resourceName, data));
    }
    return res;
  });
};

export default instance;
