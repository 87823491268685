import React, { useEffect } from 'react';
import { SessionUtils } from '../../utils';

const Logout = () => {
  useEffect(() => {
    SessionUtils.clearSession();
    setTimeout(() => {
      window.location.href = 'https://www.crccare.com/';
    }, 3000);
  }, []);

  return (
    <div className="auth-wrapper">
      <div class="cont logout-container">
        <div class="logout-container__message space-y-4">
          <p>Logout successfully.</p>
          <p>Redirecting you to the main page...</p>
          <p>
            Click{' '}
            <a
              href="https://www.crccare.com/"
              className="text-blue-500 hover:underline"
            >
              here
            </a>{' '}
            to redirect now.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Logout;
