import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import resourceReducer from './reducers/resources';
import { applyInterceptors } from './axios';

const rootReducer = combineReducers({
  resources: resourceReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

applyInterceptors(store.dispatch, store.getState);

export default store;
