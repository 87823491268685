import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MainLayout, PrivateRoute } from './components';
import ForgotPassword from './views/auth/ForgotPassword';
import Login from './views/auth/Login';
import Logout from './views/auth/Logout';
import ResetPassword from './views/auth/ResetPassword';
import Dashboard from './views/dashboard/Dashboard';
import User from './views/userManagement/User';
import UserForm from './views/userManagement/UserForm';

function App() {
  return (
    <Router>
      <MainLayout>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:token+" component={ResetPassword} />
          <Route path="/logout" component={Logout} />

          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/user-management" component={User} />
          <PrivateRoute exact path="/new/user" component={UserForm} />
          <PrivateRoute
            exact
            path="/update/user/:dataId"
            component={UserForm}
          />
        </Switch>
      </MainLayout>
    </Router>
  );
}

export default App;
