import React from 'react';
import classNames from 'classnames';

/**
 * Component for Card UI. Supports additional button and icon.
 *
 * @component
 */
export const Card = ({
  title,
  className,
  icon = null,
  right = null,
  uppercase = true,
  children,
  ...rest
}) => (
  <div
    className={`${className} flex flex-col w-full bg-white border border-gray-300 shadow-xl rounded-sm`}
    {...rest}
  >
    <div className="flex flex-row content-center items-center justify-between px-5 py-3 text-md text-gray-600 bg-gray-100 border-b border-gray-300 rounded-t-sm">
      <span
        className={classNames(
          {
            uppercase: uppercase,
          },
          'self-center flex flex-row font-semibold text-sm items-center'
        )}
      >
        {icon && (
          <span
            className={`self-center mr-2 text-2xl ${icon} text-green-700`}
          />
        )}
        <span>{title}</span>
      </span>
      {right && (
        <span className="self-center flex flex-row items-center space-x-2 whitespace-nowrap">
          {right}
        </span>
      )}
    </div>
    <div className="w-full h-full px-4 py-6 space-y-6 overflow-y-auto">
      {children}
    </div>
  </div>
);
