import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Button, Card, PageTitle, Table } from '../../components';
import { SessionUtils } from '../../utils';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getAllData as _getAlldata,
  updateData as _updateData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

const User = ({ getAllData, updateData, user }) => {
  const [userTableData, setUserTableData] = useState([]);
  const [dataChange, setDataChange] = useState(false);

  useEffect(() => {
    if (SessionUtils.getUserData().role !== 'admin') {
      return (window.location.href = `${process.env.REACT_APP_URL}/`);
    }
    getAllData(RESOURCE_NAMES['USERS'], 'show=1000');
  }, []);

  useEffect(() => {
    if (user) {
      loadUser();
    }
  }, [user]);

  useEffect(() => {
    setDataChange(!dataChange);
  }, [userTableData]);

  const loadUser = () => {
    let userData = _.values(user).map((item, index) => {
      const actions =
        SessionUtils.getUserData().role === 'admin' ? (
          <div className="flex flex-row flex-grow space-x-2">
            <Link
              to={`/update/user/${item.id}`}
              className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-green-500 text-green-500 hover:bg-green-500 hover:text-white focus:outline-none"
            >
              <div className="self-center align-middle font-normal text-lg pe-7s-pen transition duration-200" />
            </Link>
            <button
              onClick={() => handleDelete(item.id, index)}
              className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-500 text-red-500 hover:bg-red-500 hover:text-white focus:outline-none"
            >
              <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
            </button>
          </div>
        ) : (
          SessionUtils.getUserData().id === item.id && (
            <Link
              to={`/update/user/${item.id}`}
              className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-green-500 text-green-500 hover:bg-green-500 hover:text-white focus:outline-none"
            >
              <div className="self-center align-middle font-normal text-lg pe-7s-pen transition duration-200" />
            </Link>
          )
        );

      return {
        Name: item.name,
        Email: item.email,
        Role: item.role,
        Status: item.status,
        Actions: actions,
      };
    });
    setUserTableData(userData);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Archive user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        updateData(id, { status: 'inactive' })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Successfully Deleted',
              text: 'Success deleted current user',
            });
          })
          .catch(() => {
            Swal.fire({
              title: 'An Error occured!',
              icon: 'error',
            });
          });
      }
    });
  };

  return (
    <div>
      <PageTitle
        title="User Management"
        description="Manage your team and check status of users."
        className="pe-7s-users"
      />
      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        <Card
          title="USER MANAGEMENT"
          right={<Button to="/new/user" theme="success" text="Add User" />}
        >
          <Table data={userTableData} dataChange={dataChange} show={10} />
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getResource(RESOURCE_NAMES['USERS'])(state),
});

export default connect(mapStateToProps, {
  getAllData: _getAlldata,
  updateData: _updateData(RESOURCE_NAMES['USERS']),
})(User);
