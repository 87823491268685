import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import { Button, PageTitle } from '../../components';
import { Select } from '../../components/Select';
import { SessionUtils, UserService } from '../../utils';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getDataById as _getDataById,
  updateData as _updateData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

const UserForm = ({ getDataById, updateData, user }) => {
  let { dataId } = useParams();
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [originalName, setOriginalName] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');

  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  useEffect(() => {
    if (dataId) {
      if (
        SessionUtils.getUserData().role === 'admin' ||
        dataId == SessionUtils.getUserData().id
      ) {
        getDataById(RESOURCE_NAMES['USERS'], dataId)
          .then((res) => {
            setName(res.data.name ? res.data.name : '');
            setStatus(res.data.status ? res.data.status : '');
            setOrganisation(res.data.organisation ? res.data.organisation : '');
            setEmail(res.data.email ? res.data.email : '');
            setPhone(res.data.phone ? res.data.phone : '');
            setOriginalName(res.data.name ? res.data.name : '');
            setOriginalEmail(res.data.email ? res.data.email : '');
          })
          .catch(async (err) => {
            if (err.response && err.response.status === 403) {
              // token is incorrect
              await Swal.fire({
                title: 'An Error occured!',
                icon: 'error',
              });
              SessionUtils.clearSession();
              window.location.href = `${process.env.REACT_APP_URL}/login`;
            } else {
              await Swal.fire({
                title: 'An Error occured!',
                icon: 'error',
              });
            }
          });
      } else if (dataId != SessionUtils.getUserData().id) {
        return (window.location.href = `${process.env.REACT_APP_URL}/`);
      }
    }
  }, [dataId]);

  const validate = () => {
    if (password.length < 8) {
      setErrorPassword('Min have 8 characters or more.');
      return false;
    } else {
      setErrorPassword('');
    }
    if (confirmPassword.length < 8) {
      setErrorConfirmPassword('Min have 8 characters or more.');
      return false;
    } else {
      setErrorConfirmPassword('');
    }
    if (password !== confirmPassword) {
      setErrorConfirmPassword('Password does not match!');
      return false;
    } else {
      setErrorConfirmPassword('');
    }

    return true;
  };

  const handleAdd = (e) => {
    e.preventDefault();
    if (!validate()) return false;

    let dt = {
      name: name,
      email: email,
      password: password,
      organisation: organisation,
      phone: phone,
    };
    if (status !== '') dt['status'] = status;

    UserService.addUser(dt)
      .then(async () => {
        await Swal.fire({
          icon: 'success',
          title: 'Congratulations',
          text: 'Success add new user',
        });
        window.location.href = `${process.env.REACT_APP_URL}/user-management`;
      })
      .catch((err) => {
        if (err.response.data.email) {
          setErrorEmail(err.response.data.email);
        } else {
          setErrorEmail('');
        }

        if (err.response.data.name) {
          setErrorName(err.response.data.name);
        } else {
          setErrorName('');
        }
      });
  };

  const sendUpdate = async (dt) => {
    updateData(dataId, dt)
      .then(async (res) => {
        setErrorName('');
        setErrorEmail('');
        await Swal.fire({
          icon: 'success',
          title: 'Congratulations',
          text: 'Success update user data',
        });
        if (dataId == SessionUtils.getUserData().id) {
          const parsedJwt = await jwtDecode(SessionUtils.getToken());
          const userData = res.data;
          const exp = await parsedJwt.exp;
          SessionUtils.setUserData(SessionUtils.getToken(), userData, exp);
        }
        window.location.href = `${process.env.REACT_APP_URL}/user-management`;
      })
      .catch(async (err) => {
        if (err.response) {
          if (err.response.status === 400) {
            if (err.response.data.email) {
              setErrorEmail(err.response.data.email);
            } else {
              setErrorEmail('');
            }

            if (err.response.data.name) {
              setErrorName(err.response.data.name);
            } else {
              setErrorName('');
            }
          } else if (err.response.status === 403) {
            // token is incorrect
            await Swal.fire({
              title: 'An Error occured!',
              text: 'Not authorized',
              icon: 'error',
            });
            SessionUtils.clearSession();
            window.location.href = `${process.env.REACT_APP_URL}/login`;
          } else if (err.response.status === 404) {
            // user is not found or not have access to other users
            await Swal.fire({
              title: 'An Error occured!',
              text: 'Not authorized',
              icon: 'error',
            });
            window.location.href = `${process.env.REACT_APP_URL}/`;
          }
        } else {
          await Swal.fire({
            title: 'An Error occured!',
            icon: 'error',
          });
        }
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let dt = {
      organisation: organisation,
      phone: phone,
      status: status,
    };

    if (name !== originalName) dt['name'] = name;
    if (email !== originalEmail) dt['email'] = email;

    if (password === '') {
      sendUpdate(dt);
    } else {
      if (!validate()) {
        return false;
      } else {
        dt['password'] = password;
        setErrorPassword('');
        setErrorConfirmPassword('');
        sendUpdate(dt);
      }
    }
  };

  const handleDisabled = () => {
    if (!dataId) {
      return (
        name.trim() === '' ||
        organisation.trim() === '' ||
        email.trim() === '' ||
        phone === '' ||
        password === '' ||
        confirmPassword === ''
      );
    }
    if (dataId)
      return (
        name.trim() === '' ||
        organisation.trim() === '' ||
        email.trim() === '' ||
        phone === ''
      );
  };

  return (
    <div>
      {!dataId ? (
        <PageTitle
          title="New User"
          description="Add new user data."
          className="pe-7s-user"
        />
      ) : (
        <PageTitle
          title="Update User"
          description="Edit user data."
          className="pe-7s-user"
        />
      )}

      <form
        className="flex flex-col px-6 pt-2 pb-6 space-y-8"
        onSubmit={!dataId ? handleAdd : handleUpdate}
      >
        <div className="flex flex-row space-x-3">
          <div className="lg:w-3/4">
            <p className="text-sm mb-1">Full Name</p>
            <input
              className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
              name="fullname"
              type="text"
              placeholder="Full name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required={true}
            />
            {errorName.trim() !== '' && (
              <p className="text-sm text-red-600 mt-1">{errorName}</p>
            )}
          </div>
          <div className="lg:w-1/4">
            <p className="text-sm mb-1">Status</p>
            <Select
              fullWidth
              className={'text-sm px-3 py-2'}
              placeholder="Choose status"
              data={[
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
              ]}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            />
          </div>
        </div>
        <div>
          <p className="text-sm mb-1">Organisation</p>
          <input
            className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
            name="organisation"
            type="text"
            placeholder="Organisation"
            onChange={(e) => setOrganisation(e.target.value)}
            value={organisation}
            required={true}
          />
        </div>
        <div className="flex flex-row space-x-3">
          <div className="lg:w-1/2">
            <p className="text-sm mb-1">Email</p>
            <input
              className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
              name="email"
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required={true}
            />
            {errorEmail.trim() !== '' && (
              <p className="text-sm text-red-600 mt-1">{errorEmail}</p>
            )}
          </div>
          <div className="lg:w-1/2">
            <p className="text-sm mb-1">Phone</p>
            <input
              className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
              name="phone"
              type="number"
              placeholder=""
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              required={true}
            />
          </div>
        </div>
        <div className="flex flex-row space-x-3">
          <div className="lg:w-1/2">
            <p className="text-sm mb-1">Password</p>
            <input
              className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {errorPassword.trim() !== '' && (
              <p className="text-sm text-red-600 mt-1">{errorPassword}</p>
            )}
          </div>
          <div className="lg:w-1/2">
            <p className="text-sm mb-1">Verify Password</p>
            <input
              className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
              name="verify"
              type="password"
              placeholder="Verify Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            {errorConfirmPassword.trim() !== '' && (
              <p className="text-sm text-red-600 mt-1">
                {errorConfirmPassword}
              </p>
            )}
          </div>
        </div>
        <div>
          <Button
            lowercase
            theme="success"
            text={!dataId ? 'Save' : 'Update Data'}
            disabled={handleDisabled()}
          />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getResource(RESOURCE_NAMES['USERS'])(state),
});

export default connect(mapStateToProps, {
  getDataById: _getDataById,
  updateData: _updateData(RESOURCE_NAMES['USERS']),
})(UserForm);
