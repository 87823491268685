import axios from 'axios';
import classNames from 'classnames';
import React, { useState } from 'react';
import logo_crc from '../../assets/images/logo_crc-white.png';
import './auth.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    let dt = {
      email: email,
    };
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/request-reset-password`,
      headers: {
        'content-type': 'application/json',
      },
      data: dt,
    })
      .then(() => {
        setIsSubmitted(true);
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSubmitted(true);
        setIsSuccess(false);
      });
  };

  return (
    <div className="auth-wrapper">
      <div className="cont-forgot">
        <div
          className={classNames('alert alert-success alert-dismissible fade', {
            show: isSubmitted && isSuccess,
          })}
          role="alert"
        >
          {isSubmitted && isSuccess && (
            <>
              <strong>Recover Success!</strong> We will send an email with the
              reset password link to you shortly.
            </>
          )}
        </div>
        <div
          className={classNames('alert alert-danger alert-dismissible fade', {
            show: isSubmitted && !isSuccess,
          })}
          role="alert"
        >
          {isSubmitted && !isSuccess && (
            <>
              <strong>
                <strong>Error!</strong> User not found, please request your
                reset link again.{' '}
              </strong>
              <a
                href="/forgot-password"
                className="text-blue-500 hover:underline"
              >
                Request
              </a>
            </>
          )}
        </div>

        <form
          id="form-forgot-password"
          method="POST"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="form reset-password">
            <h2 className="auth-h2" style={{ lineHeight: 1.2 }}>
              Request Password Reset
            </h2>
            <label className="auth-label">
              <span>Input Your Email</span>
              <input
                type="email"
                name="email"
                className="auth-input focus:outline-none"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </label>
            <button type="submit" className="auth-button submit">
              Recover my data
            </button>
            <p className="forgot-pass">
              <a href="/">Home</a>
            </p>
          </div>
        </form>
        <div className="sub-cont">
          <div className="img">
            <div className="img__text m--up">
              <img src={logo_crc} alt="" className="px-4" />
              <h2 className="auth-h2" style={{ lineHeight: 1.2 }}>
                Lost your password?
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
