import React from 'react';
import { Redirect, Route } from 'react-router';

import { SessionUtils } from '../utils';

/**
 * Component for PrivateRoute.
 *
 * Redirects user to Auth page if user is not authenticated.
 *
 * @component
 */
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const PrivateComponent = (props) => {
    if (SessionUtils.isAuthed()) return <Component {...props} />;
    else return <Redirect to="/login" />;
  };

  return <Route {...rest} render={PrivateComponent} />;
};
