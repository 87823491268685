import axios from 'axios';
import classNames from 'classnames';
import React, { useState } from 'react';
import logo_crc from '../../assets/images/logo_crc-white.png';
import './auth.scss';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
  const token = useParams().token;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  const validate = () => {
    if (password.length < 8) {
      setErrorPassword('Min have 8 characters or more.');
      return false;
    } else {
      setErrorPassword('');
    }
    if (confirmPassword.length < 8) {
      setErrorConfirmPassword('Min have 8 characters or more.');
      return false;
    } else {
      setErrorConfirmPassword('');
    }
    if (password !== confirmPassword) {
      setErrorConfirmPassword('Password does not match!');
      return false;
    } else {
      setErrorConfirmPassword('');
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    if (!validate()) return false;

    let dt = {
      token: token,
      password: password,
    };
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/reset-password`,
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
      data: dt,
    })
      .then(() => {
        setIsSubmitted(true);
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSubmitted(true);
        setIsSuccess(false);
      });
  };

  return (
    <div className="auth-wrapper">
      <div className="cont">
        <div
          className={classNames('alert alert-success alert-dismissible fade', {
            show: isSubmitted && isSuccess,
          })}
          role="alert"
        >
          {isSubmitted && isSuccess && (
            <>
              <strong>Successful!</strong> Your password has been reset.
            </>
          )}
        </div>
        <div
          className={classNames('alert alert-danger alert-dismissible fade', {
            show: isSubmitted && !isSuccess,
          })}
          role="alert"
        >
          {isSubmitted && !isSuccess && (
            <>
              <strong>
                <strong>Error!</strong> Token not found, please request your
                reset link again.{' '}
              </strong>
              <a
                href="/forgot-password"
                className="text-blue-500 hover:underline"
              >
                Request
              </a>
            </>
          )}
        </div>

        <form
          className={classNames('reset-password-form', {
            hidden: isSubmitted && isSuccess,
          })}
          method="POST"
          onSubmit={(e) => handleSubmit(e)}
        >
          <input type="hidden" name="resetPasswordToken" value={token} />
          <div className="form">
            <label htmlFor="password" className="auth-label">
              <span>New Password</span>
              <input
                id="password"
                type="password"
                name="password"
                className="auth-input focus:outline-none"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <p className="text-danger error-password">{errorPassword}</p>
            </label>
            <label htmlFor="confirm-password" className="auth-label">
              <span>Confirm Password</span>
              <input
                id="confirm-password"
                type="password"
                name="confirmPassword"
                className="auth-input focus:outline-none"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
              <p className="text-danger error-confirm-password">
                {errorConfirmPassword}
              </p>
            </label>
            <button type="submit" className="auth-button submit">
              Recover my data
            </button>
            <p className="forgot-pass">
              <a href="/">Home</a>
            </p>
          </div>
        </form>

        <div className="sub-cont">
          <div className="img">
            <div className="img__text m--up">
              <img src={logo_crc} alt="" className="px-4" />
              <h2 className="auth-h2">Lost your password?</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
