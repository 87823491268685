import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import useOnclickOutside from 'react-cool-onclickoutside';
import classNames from 'classnames';

import { SessionUtils, useWindowSize } from '../utils';
import { ScrollToTop } from './ScrollToTop';

import userAvatarImg from '../assets/images/user_avatar.jpg';
import logoCRC from '../assets/images/logo_crc-white.png';

/** Array of side bar menu items */
const sideMenuList = [
  {
    header: '',
    children: [
      {
        pageName: 'Dashboard',
        route: '/',
        icon: 'pe-7s-browser',
      },
      {
        adminOnly: true,
        pageName: 'User Management',
        route: '/user-management',
        icon: 'pe-7s-users',
      },
    ],
  },
];

export const MainLayout = ({ children }) => {
  /** State to store information related for mobile detection, user menu, and switch menu */
  const location = useLocation();
  const [width] = useWindowSize();
  const [isMobile, setMobile] = useState(false);
  const [showSidebar, setSidebar] = useState(false);
  const [showUserMenu, setUserMenu] = useState(false);
  const refSidebar = useOnclickOutside(() => isMobile && setSidebar(false));
  const refUser = useOnclickOutside(() => setUserMenu(false));

  /** Effect to detect if user is on mobile platform */
  useEffect(() => {
    if (width < 1024 && !isMobile) setMobile(true);
    if (width > 1024 && isMobile) setMobile(false);
  }, [width, isMobile]);

  /** Effect to hide sidebar if user is not logged in */
  useEffect(() => {
    if (SessionUtils.isAuthed()) setSidebar(!isMobile);
    else setSidebar(false);
  }, [isMobile]);

  const isAuthRoute = () => {
    if (location.pathname.startsWith('/login')) return true;
    if (location.pathname.startsWith('/register')) return true;
    if (location.pathname.startsWith('/logout')) return true;
    if (location.pathname.startsWith('/forgot-password')) return true;
    if (location.pathname.startsWith('/reset-password')) return true;

    return false;
  };

  if (isAuthRoute()) return children;

  return (
    <div>
      {/*Top bar*/}
      <header
        className={classNames(
          { hidden: !SessionUtils.isAuthed() },
          'bg-xgreen fixed top-0 w-full h-20 z-50 flex flex-col content-center justify-center px-0 py-3 shadow-lg'
        )}
      >
        <div className="self-center flex space-y-0 flex-row content-center justify-between w-full">
          <div className="flex flex-row">
            {/*Logo & Hamburger menu*/}
            <div
              className={classNames(
                {
                  'lg:flex-row': showSidebar,
                  'lg:flex-row-reverse': !showSidebar,
                },
                'flex flex-row-reverse h-20 w-full max-w-lg lg:w-72 justify-center items-center lg:bg-white lg:bg-opacity-10 transition'
              )}
            >
              <div className='flex flex-grow justify-center'>
                <a href="https://www.crccare.com/" className="">
                  <img
                    className="h-10 w-auto m-2 lg:h-12"
                    src={logoCRC}
                    alt="logo"
                  />
                </a>
              </div>
              <button
                type="button"
                className={`cursor-pointer self-center m-2 focus:outline-none ${
                  isMobile && showSidebar && 'pointer-events-none'
                }`}
                onClick={() => setSidebar(!showSidebar)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {isMobile && showSidebar ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>

          {/*Switch app and account menu*/}
          <div className="flex flex-row content-center justify-center h-20 px-6 space-x-6">
            {/*User menu & avatar*/}
            {SessionUtils.getUserData() && (
              <div className="relative flex flex-row">
                <div className="hidden md:flex flex-col items-center justify-center mr-5">
                  <span className="border-l h-7"></span>
                </div>
                <button
                  type="button"
                  onClick={() => setUserMenu(!showUserMenu)}
                  className={`flex space-x-1 focus:outline-none ${
                    showUserMenu && 'pointer-events-none'
                  }`}
                >
                  <img
                    className="self-center hidden h-10 rounded-3xl mr-1 md:block"
                    src={userAvatarImg}
                    alt="logo"
                  />
                  <div className="self-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 lg:h-4 lg:w-4 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </button>

                {/* User Information */}
                <div className="hidden lg:flex flex-col justify-center ml-4">
                  <p className="font-semibold text-sm text-gray-100">
                    {SessionUtils.getUserData().name}
                  </p>
                  <p className="text-sm text-gray-300">
                    {SessionUtils.getUserData().role}
                  </p>
                </div>

                {/*User Dropdown*/}
                <div
                  ref={refUser}
                  className={classNames(
                    {
                      'opacity-0 invisible pointer-events-none': !showUserMenu,
                      'opacity-100 visible': showUserMenu,
                    },
                    'absolute right-0 md:-left-28 top-16 w-48 px-6 py-4 text-left transition-all duration-500 ease-in-out text-sm bg-white border border-gray-300 rounded shadow-xl z-50'
                  )}
                >
                  <div className="mb-2 uppercase font-semibold text-xgreen">
                    {SessionUtils.getUserData().name}
                  </div>
                  <div className="font-semibold text-gray-700 space-y-2">
                    <a
                      href={`https://crccaretools.com.au/update/user/${
                        SessionUtils.getUserData().id
                      }`}
                      className="group flex flex-row w-full font-normal rounded transition duration-200 hover:bg-xbluetint"
                    >
                      <div className="menu-text self-center">User account</div>
                    </a>
                    <button
                      onClick={() => {
                        Swal.fire({
                          title: 'Sign out?',
                          text: 'Are you sure you want to sign out?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#28a745',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Sign out',
                        }).then((result) => {
                          if (result.value) {
                            SessionUtils.clearSession();
                            window.location.href = `${process.env.REACT_APP_URL}/logout`;
                          }
                        });
                      }}
                      type="button"
                      className="group flex flex-row w-full rounded transition duration-200 hover:bg-xbluetint"
                    >
                      <div className="menu-text self-center">Sign out</div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      {/*Sidebar*/}
      <nav
        ref={refSidebar}
        className={classNames({
          'flex flex-col fixed inset-y-0 left-0 w-72 mt-20 pt-8 pb-6 bg-white overflow-y-auto transform transition duration-200 ease-in-out z-40': true,
          '-translate-x-full': !showSidebar,
        })}
        style={{
          boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 20px 0px',
        }}
      >
        <div className="px-7">
          {sideMenuList.map((section, index) => (
            <div className="py-2" key={index}>
              <div className="mb-3 uppercase text-sm font-semibold text-xgreen">
                {section.header}
              </div>
              <div className="menu font-normal text-gray-700 space-y-1">
                {section.children.map((menu, index) => {
                  if (
                    menu.adminOnly &&
                    SessionUtils.getUserData().role !== 'admin'
                  ) {
                    return null;
                  }
                  if (menu.route.startsWith('http')) {
                    return (
                      <a
                        key={index}
                        href={menu.route}
                        onClick={() => isMobile && setSidebar(false)}
                        className="group flex flex-row w-full py-1 px-3 space-x-3 rounded transition duration-200 hover:bg-xbluetint"
                      >
                        <div
                          className={`menu-icon text-2xl opacity-60 group-hover:opacity-100 transition duration-200 ${menu.icon}`}
                        ></div>
                        <div className="menu-text text-sm self-center">
                          {menu.pageName}
                        </div>
                      </a>
                    );
                  }
                  return (
                    <NavLink
                      key={index}
                      to={menu.route}
                      exact={menu.route === '/'}
                      onClick={() => isMobile && setSidebar(false)}
                      className="group flex flex-row w-full py-1 px-3 space-x-3 rounded transition duration-200 hover:bg-xbluetint"
                    >
                      <div
                        className={`text-2xl opacity-50 group-hover:opacity-100 transition duration-200 ${menu.icon}`}
                      ></div>
                      <div className="menu-text text-sm self-center">
                        {menu.pageName}
                      </div>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </nav>

      {/*Main Content*/}
      <ScrollToTop>
        <div
          className={classNames(
            {
              'ml-0 mt-20': isMobile || (!isMobile && !showSidebar),
              'ml-72 mt-20 pt-2':
                !isMobile && showSidebar && SessionUtils.isAuthed(),
            },
            'flex flex-col max-w-full h-full min-h-90 2xl:min-h-screen bg-gray-100 transition-all'
          )}
        >
          {/*Content*/}
          {children}

          {/*Copyright Footer*/}
          <div className="flex flex-row w-full justify-center p-8 bg-white border-t border-gray-200 mt-auto">
            <div className="flex flex-col text-sm">
              &copy; Copyright {new Date().getFullYear()} – CRC CARE
            </div>
          </div>
        </div>
      </ScrollToTop>
    </div>
  );
};
