import React, { useEffect, useState } from 'react';
import './auth.scss';
import logo_crc from '../../assets/images/logo_crc-white.png';
import axios from 'axios';
import classNames from 'classnames';
import { useWindowSize } from '../../utils/useWindowSize';
import { AES } from 'crypto-js';
import { SessionUtils, UserService, USER_STATUS } from '../../utils';
import jwtDecode from 'jwt-decode';

const Login = () => {
  const [width] = useWindowSize();
  const [isMobile, setMobile] = useState(false);
  const [clickRegMob, setClickRegMob] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [fullName, setFullName] = useState('');
  const [organization, setOrganization] = useState('');
  const [phone, setPhone] = useState('');
  const [rEmail, setREmail] = useState('');
  const [rPassword, setRPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isRegSuccess, setIsRegSuccess] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  const [showForm, setShowForm] = useState(false);

  /** Effect to detect if user is on mobile platform */
  useEffect(() => {
    if (width < 575.98 && !isMobile) setMobile(true);
    if (width > 575.98 && isMobile) setMobile(false);
  }, [width, isMobile]);

  useEffect(() => {
    if (document.querySelector('.img__btn')) {
      document
        .querySelector('.img__btn')
        .addEventListener('click', function () {
          document.querySelector('.cont').classList.toggle('s--signup');
        });
    }
  }, [showForm]);

  useEffect(() => {
    if (SessionUtils.isAuthed()) {
      redirect();
    } else {
      setShowForm(true);
    }
  }, []);

  const redirect = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const param = url.searchParams.get('callback');
    const tokenData = SessionUtils.getToken();

    if (param === null) {
      window.location.href = process.env.REACT_APP_URL;
    } else {
      const encryptedText = AES.encrypt(
        tokenData,
        process.env.REACT_APP_JWT_SECRET
      ).toString();
      window.location.href = `${param}/login/${encryptedText}`;
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let dt = {
      email: email,
      password: password,
    };
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/login`,
      headers: {
        'content-type': 'application/json',
      },
      data: dt,
    })
      .then(async (res) => {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const param = url.searchParams.get('callback');

        const tokenDecrypted = res.data.token;
        const verifiedData = await UserService.verifyGet(tokenDecrypted);
        const parsedJwt = await jwtDecode(tokenDecrypted);
        if (verifiedData) {
          const userData = verifiedData;
          const exp = await parsedJwt.exp;
          SessionUtils.setUserData(tokenDecrypted, userData, exp);
          if (param === null) {
            window.location.href = process.env.REACT_APP_URL;
          } else {
            const encryptedText = AES.encrypt(
              tokenDecrypted,
              process.env.REACT_APP_JWT_SECRET
            ).toString();
            window.location.href = `${param}/login/${encryptedText}`;
          }
        } else {
          SessionUtils.clearSession();
          alert('Authentication is not permitted!');
        }
      })
      .catch(() => {
        setIsLoginError(true);
      });
  };

  const handleClickReg = (e) => {
    e.preventDefault();
    setClickRegMob(!clickRegMob);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (!validate()) return false;

    const data = {
      name: fullName,
      email: rEmail,
      password: rPassword,
      organisation: organization,
      phone: phone,
      status: USER_STATUS.INACTIVE,
    };

    UserService.addUser(data)
      .then(() => {
        setIsRegSuccess(true);
        setErrorEmail('');
        setErrorName('');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 400) {
            if (err.response.data.email) {
              setErrorEmail(err.response.data.email);
            } else {
              setErrorEmail('');
            }
            if (err.response.data.name) {
              setErrorName(err.response.data.name);
            } else {
              setErrorName('');
            }
          }
        }
      });
  };

  const validate = () => {
    if (rPassword.length < 8) {
      setErrorPassword('Min have 8 characters or more.');
      return false;
    } else {
      setErrorPassword('');
    }
    if (confirmPassword.length < 8) {
      setErrorConfirmPassword('Min have 8 characters or more.');
      return false;
    } else {
      setErrorConfirmPassword('');
    }
    if (rPassword !== confirmPassword) {
      setErrorConfirmPassword('Password does not match!');
      return false;
    } else {
      setErrorConfirmPassword('');
    }

    return true;
  };

  if (!showForm) {
    return <div></div>;
  }

  return (
    <div className="login-wrapper">
      <div className="cont">
        <form
          method="POST"
          className={classNames(
            {
              block: !clickRegMob && isMobile,
              hidden: clickRegMob && isMobile,
            },
            'form-login'
          )}
          onSubmit={(e) => handleLogin(e)}
        >
          <div
            className={classNames('alert alert-danger alert-dismissible fade', {
              show: isLoginError,
            })}
            role="alert"
          >
            {isLoginError && (
              <>
                <strong>Auth failed! </strong> Please check your auth status or
                your input!
              </>
            )}
          </div>

          <div className="form sign-in">
            <h2 className="auth-h2">Welcome to CRC CARE</h2>
            <label className="auth-label">
              <span>Email</span>
              <input
                className="auth-input focus:outline-none"
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </label>
            <label className="auth-label">
              <span>Password</span>
              <input
                className="auth-input focus:outline-none"
                type="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </label>
            <p className="forgot-pass">
              <a href="/forgot-password">Forgot password?</a>
            </p>
            <button type="submit" className="auth-button submit">
              Sign In
            </button>
            <button
              className="auth-button submit btn-register"
              onClick={(e) => handleClickReg(e)}
            >
              Register
            </button>
          </div>
        </form>
        <div
          className="sub-cont"
          style={
            isMobile
              ? clickRegMob
                ? { display: 'block' }
                : { display: 'none' }
              : {}
          }
        >
          <div className="img">
            <div className="img__text m--up">
              <img src={logo_crc} alt="" className="px-4" />
              <h2 className="auth-h2">New user?</h2>
              <p>
                Click SIGN UP, fill in your details and start your journey with
                us.
              </p>
            </div>
            <div className="img__text m--in">
              <img src={logo_crc} alt="" className="px-4" />
              <h2 className="auth-h2">One of us?</h2>
              <p>If you already have an account, just sign in below.</p>
            </div>
            <div className="img__btn">
              <span className="m--up">Sign Up</span>
              <span className="m--in">Sign In</span>
            </div>
          </div>
          <div className="form sign-up">
            <div
              className={classNames(
                'alert alert-success alert-dismissible fade',
                {
                  show: isRegSuccess,
                }
              )}
              role="alert"
            >
              {isRegSuccess && (
                <>
                  <strong>Congratulations!</strong> We will send an email
                  confirmation once your account has been activated.
                </>
              )}
            </div>
            <h2 className="auth-h2">Request for CRC CARE</h2>
            <form
              className="register-form"
              method="POST"
              onSubmit={(e) => handleRegister(e)}
            >
              <div className="flex flex-col md:flex-row md:justify-around">
                <div className="col-md-6">
                  <label className="auth-label">
                    <span>Full Name</span>
                    <input
                      className="auth-input focus:outline-none"
                      type="text"
                      required
                      autoFocus
                      name="name"
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                    />
                    <p className="text-danger error-name">{errorName}</p>
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="auth-label">
                    <span>Organisation</span>
                    <input
                      className="auth-input focus:outline-none"
                      type="text"
                      required
                      name="organisation"
                      onChange={(e) => setOrganization(e.target.value)}
                      value={organization}
                    />
                  </label>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:justify-around">
                <div className="col-md-6">
                  <label className="auth-label">
                    <span>Email Address</span>
                    <input
                      className="auth-input focus:outline-none"
                      type="email"
                      required
                      name="email"
                      onChange={(e) => setREmail(e.target.value)}
                      value={rEmail}
                    />
                    <p className="text-danger error-email">{errorEmail}</p>
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="auth-label">
                    <span>Phone</span>
                    <input
                      className="auth-input focus:outline-none"
                      type="number"
                      required
                      name="phone"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </label>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:justify-around">
                <div className="col-md-6">
                  <label className="auth-label">
                    <span>Password</span>
                    <input
                      className="auth-input focus:outline-none"
                      type="password"
                      name="password"
                      id="password"
                      onChange={(e) => setRPassword(e.target.value)}
                      value={rPassword}
                    />
                    <p className="text-danger error-password">
                      {errorPassword}
                    </p>
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="auth-label">
                    <span>Verify Password</span>
                    <input
                      className="auth-input focus:outline-none"
                      type="password"
                      name="verify_password"
                      id="confirm-password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                    <p className="text-danger error-confirm-password">
                      {errorConfirmPassword}
                    </p>
                  </label>
                </div>
              </div>
              <button type="submit" className="auth-button submit">
                Sign Up
              </button>
              <button
                className="auth-button submit btn-login"
                onClick={(e) => handleClickReg(e)}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
