import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

/**
 * Component for Button UI.
 *
 * @component
 */
export const Button = ({
  className,
  inverted,
  text,
  style,
  icon,
  lowercase,
  rounded,
  to,
  href,
  theme,
  disabled,
  ...rest
}) => {
  const buttonClassName = classNames(
    'cursor-pointer text-center flex py-2 px-3 focus:outline-none',
    {
      'hover:underline': style === 'link',
      'transition shadow-lg': style === 'button',
    },
    {
      'rounded-full': style === 'button' && rounded,
      'rounded-button': style === 'button' && !rounded,
    },
    {
      'disabled:opacity-40 disabled:pointer-events-none': disabled,
    },
    // handle text color
    {
      'text-white': !inverted,
      'text-xgreen': (style === 'link' || inverted) && theme === 'success',
      'text-blue-400': (style === 'link' || inverted) && theme === 'info',
      'text-red-400': (style === 'link' || inverted) && theme === 'error',
    },
    // handle border color
    {
      'border-xgreen': style === 'button' && theme === 'success',
      'border-blue-400': style === 'button' && theme === 'info',
      'border-red-400': style === 'button' && theme === 'error',
    },
    // handle background color
    {
      'bg-xgreen hover:bg-green-600':
        style === 'button' && !inverted && theme === 'success',
      'bg-blue-400 hover:bg-blue-500':
        style === 'button' && !inverted && theme === 'info',
      'bg-red-400 hover:bg-red-600':
        style === 'button' && !inverted && theme === 'error',
    },
    className
  );

  const buttonContent = (
    <>
      {icon && (
        <div
          className={`mr-2 menu-icon self-center align-middle font-extrabold ${icon} group-hover:opacity-60 transition duration-200`}
        />
      )}
      <div className={`text-sm ${!lowercase && 'uppercase'}`}>{text}</div>
    </>
  );

  // Regular action button (non-link)
  if (!to && !href) {
    return (
      <button className={buttonClassName} disabled={disabled} {...rest}>
        {buttonContent}
      </button>
    );
  }

  // Router button (internal link)
  if (to && !href) {
    return (
      <Link to={to} className={buttonClassName} disabled={disabled} {...rest}>
        {buttonContent}
      </Link>
    );
  }

  // Anchor button (external link)
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={buttonClassName}
      disabled={disabled}
      {...rest}
    >
      {buttonContent}
    </a>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.oneOf(['button', 'link']),
  inverted: PropTypes.bool,
  lowercase: PropTypes.bool,
  rounded: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  theme: PropTypes.oneOf(['success', 'info', 'error']),
};

Button.defaultProps = {
  style: 'button',
  lowercase: false,
  inverted: false, // invert the background and text color
  rounded: false,
  theme: 'info',
};
